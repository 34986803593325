import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { EssDialogService } from 'ngx-essentia';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../../../../shared/services/responsive.service';;
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateMigratedPipe } from '../../../../shared/translation/trans-migrated.pipe';
import { ThemesService } from '../../../../whoo/shared/theme/themes.service';
import { NavigationService } from '../../../../whoo/core/navigation.service';

@Component({
    selector: 'event-examples-item',
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        MatTooltipModule,
    ],
    templateUrl: './event-examples-item.html',
    styleUrls: ['./event-examples-item.scss'],
})
export class EventExamplesItemComponent implements OnInit {
    @Input() title: string;
    @Input() imageUrlBigScreen: string;
    @Input() imageUrlSmallScreen: string;
    @Input() description: string;
    @Input() imageRight = true;
    @Input() templateKey: string; /** the template that should open on click - if kept empty the showImageDialog is used */
    @Input() themeKey: string; /** not required, the theme that should used if click */


    private dialogRef: MatDialogRef<unknown>;

    constructor(
        private logger: LoggerService,
        public responsiveService: ResponsiveService,
        public dialog: MatDialog,
        private dialogService: EssDialogService,
        public themesService: ThemesService,
        private navigationService: NavigationService,
    ) { }

    ngOnInit() { }


    get imageUrl() {
        if (this.imageUrlSmallScreen && this.responsiveService.isScreenSmall()) {
            return this.imageUrlSmallScreen;
        }
        return this.imageUrlBigScreen;
    }



    public clickImage(): void {
        if (this.templateKey) {
            this.navigationService.navigateCreatePoll(
                '',
                this.templateKey,
                this.themeKey
            );
        } else {
            this.openImageDialog();
        }

    }

    private openImageDialog() {
        this.dialogRef?.close();
        const data = {
            title: this.title,
            description: this.description,
            imageUrl: this.imageUrl,
        }
        import('../../../../shared/show-image-dialog/show-image-dialog').then(
            (module) => {
                this.dialogRef = this.dialogService.open(
                    module.ShowImageDialog,
                    data
                );
            }
        );
    }
}
