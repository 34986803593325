import { Component, Input } from '@angular/core';

@Component({
    template: '',

})
export class AbstractLandingPageSection {
    @Input() templateKey: string
    @Input() landingPageKey: string
    @Input() sectionKey: string
    constructor(
    ) { }
    get labelPrefix() {
        return this.scopeKey + '.' + this.sectionKey + '.'
    }

    get scopeKey() {
        if (this.landingPageKey) {
            return this.landingPageKey
        } else {
            return this.templateKey
        }
    }

    getTransLocoArrayKey(arrayKey): string {
        return 'page/' + this.scopeKey.toLowerCase() + '.' + this.sectionKey + '.' + arrayKey
    }
}
