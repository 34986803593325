import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { EssDialogService, EssTranslationService, NgxEssentiaModule, TranslateLandingPagePipe } from 'ngx-essentia';
import { NGXLogger as LoggerService } from "ngx-logger";
import { TemplateKey } from '../../../whoo/core/poll-templates/template.configuration';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';
import { CreatePollButton } from '../shared/create-poll-button/create-poll.button';
import { FounderStatementComponent } from '../shared/founder-statement/founder-statement';
import { HowItWorksItem } from '../shared/how-it-works-item/how-it-works-item';
import { TestemonialItem } from '../shared/testimonial-item/testimonial-item';
import { SectionTopComponent } from '../shared/section-top/section-top.component';
import { PollTemplatesComponent } from '../../../whoo/shared/poll-templates/poll-templates.component';
import { TranslateTemplatePipe } from '../../../whoo/core/poll-templates/template-label/template-label.pipe';
import { WhocanAdvantagesComponent } from '../shared/advantages/whocan-advantages.component';
import { WhyWhocanComponent } from '../shared/why-whocan/why-whocan.component';
import { HowItWorksComponent } from '../shared/how-it-works/how-it-works.component';
import { TestemonialStandalone } from '../shared/testimonial-standalone/testimonial-standalone';
import { AbstractLandingPage } from '../abstract-landing-page/abstract-landing-page';
import { EventExamplesComponent } from "../shared/event-examples/event-examples.component";
import { ThemeCardsSectionComponent } from "../shared/theme-cards-section/theme-cards-section.component";
import { LatestBlogPostsSectionComponent } from "../shared/latest-blog-bosts/latest-blog-posts.component";

@Component({
    templateUrl: './home.page.html',
    styleUrls: ['./home.page.scss'],

    standalone: true,
    imports: [
        CommonModule,
        MatDividerModule,
        RouterModule,
        // Standalone components
        CreatePollButton,
        FounderStatementComponent,
        HowItWorksItem,
        TestemonialItem,
        SectionTopComponent,
        WhocanAdvantagesComponent,
        PollTemplatesComponent,
        TranslateTemplatePipe,
        WhyWhocanComponent,
        HowItWorksComponent,
        TestemonialStandalone,
        TranslateLandingPagePipe,
        NgxEssentiaModule,
        EventExamplesComponent,
        ThemeCardsSectionComponent,
        LatestBlogPostsSectionComponent
    ],
})
export class HomePage extends AbstractLandingPage {
    pollTitle = '';
    dialogRef: MatDialogRef<unknown>;
    templateKey = TemplateKey.advanced
    public eventExamplesBigScreenImages = [
        "/assets/images/landing-page/birthday-invitation.webp",
        "/assets/images/poll-templates/food-on-the-table-breakfast.webp",
        "/assets/images/poll-templates/poll-preview/calendar-desk.webp",

    ]
    public eventExamplesSmallScreenImages = [
        "/assets/images/poll-templates/birthday-invitation-preview.webp",
        "/assets/images/poll-templates/food-on-the-table-breakfast.webp",
        "/assets/images/poll-templates/poll-preview/calendar-desk.webp",
    ]
    public templateKeys = [
        "invitation",
        "potluck",
        "schedule",
    ]
    public themeKeys = [
        "ballons",
        "potluck",
        "",
    ]
    constructor(
        protected logger: LoggerService,
        public dialog: MatDialog,
        private dialogService: EssDialogService,
        protected essTrans: EssTranslationService
    ) {
        super(essTrans);
    }
    createPoll(): void {
        import('../../../whoo/shared/poll/create-poll-dialog/create-poll-dialog').then(
            (module) => {
                this.logger.log('pollTitle: ', this.pollTitle);
                this.dialogRef?.close(); // make sure we don't have multiple dialog open
                this.dialogRef = this.dialogService.open(
                    module.CreatePollDialog,
                    {
                        pollTitle: this.pollTitle,
                    }

                );
            }
        );
    }

    get hideTemplates(): string[] {
        return ['advanced', 'rsvp',];
    }


}
