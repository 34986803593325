/**
 * This is the same file for all languages except english
 * Why we need these copies is because it's pulling in the dictionary.json in the same folder which is lanugage dependend
 */
import { environment } from 'projects/whocan-app/src/environments/environment';
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable max-len */

import routes from '../../../assets/locale/pt/routes.pt.dic.json';
import seoDictionary from '../../../assets/locale/pt/seo.pt.dic.json';



const standardTitle = seoDictionary.app.standardTitle + ' | ' + environment.brandName;
const descriptionPostfix = seoDictionary.app.descriptionPostfix;
const standardDescription = seoDictionary.app.standardDescription + descriptionPostfix;
const seperator = " - ";



export const routesSeo = {
    "homeTemplates": routes.routesLandingPages.homeTemplates,
    "homeTemplates.title": seoDictionary.home.home.headLine1,
    "homeTemplates.description": seoDictionary.home.home.headLine2 + descriptionPostfix,

    "schedule": routes.routesLandingPages.schedule,
    "schedule.title": seoDictionary.schedule.home.headLine1,
    "schedule.description": seoDictionary.schedule.home.headLine2 + descriptionPostfix,

    "doodleAlternative": routes.routesLandingPages.doodleAlternative,
    "doodleAlternative.title": seoDictionary.doodleAlternative.home.headLine1,
    "doodleAlternative.description": seoDictionary.doodleAlternative.home.headLine2 + descriptionPostfix,

    "invitation": routes.routesLandingPages.invitation,
    "invitation.title": seoDictionary.invitation.home.headLine1,
    "invitation.description": seoDictionary.invitation.home.headLine2 + descriptionPostfix,

    "birthdayInvitation": routes.routesLandingPages.birthdayInvitation,
    "birthdayInvitation.title": seoDictionary.birthdayInvitation.home.headLine1,
    "birthdayInvitation.description": seoDictionary.birthdayInvitation.home.headLine2 + descriptionPostfix,


    "duties": routes.routesLandingPages.duties,
    "duties.title": seoDictionary.duties.home.headLine1,
    "duties.description": seoDictionary.duties.home.headLine2 + descriptionPostfix,

    "dutiesDate": routes.routesLandingPages.dutiesDate,
    "dutiesDate.title": seoDictionary.duties.home.headLine1,
    "dutiesDate.description": seoDictionary.duties.home.headLine2 + descriptionPostfix,

    "potluck": routes.routesLandingPages.potluck,
    "potluck.title": seoDictionary.potluck.home.headLine1,
    "potluck.description": seoDictionary.potluck.home.headLine2 + descriptionPostfix,

    "preference": routes.routesLandingPages.preference,
    "preference.title": seoDictionary.preference.home.headLine1,
    "preference.description": seoDictionary.preference.home.headLine2 + descriptionPostfix,

    "registration": routes.routesLandingPages.registration,
    "registration.title": seoDictionary.registration.home.headLine1,
    "registration.description": seoDictionary.registration.home.headLine2 + descriptionPostfix,

    "signupsheets": routes.routesLandingPages.signupsheets,
    "signupsheets.title": seoDictionary.signupsheets.home.headLine1,
    "signupsheets.description": seoDictionary.registration.home.headLine2 + descriptionPostfix,

    "order": routes.routesLandingPages.order,
    "order.title": seoDictionary.order.home.headLine1,
    "order.description": seoDictionary.order.home.headLine2 + descriptionPostfix,

    "lunchgroup": routes.routesLandingPages.registration,
    "lunchgroup.title": seoDictionary.lunchgroup.home.headLine1,
    "lunchgroup.description": seoDictionary.lunchgroup.home.headLine2 + descriptionPostfix,

    "blog": routes.app.blog,
    "blog.title": standardTitle + seperator + seoDictionary.app.title.blog,
    "blog.description": seoDictionary.app.description.blog + seperator + standardDescription,

    "404": routes.app[404],
    "404.title": standardTitle + seperator + seoDictionary.app.title[404],
    "404.shortTitle": seoDictionary.app.title[404],

    "about": routes.app.about,
    "about.shortTitle": seoDictionary.app.title.about,
    "about.title": standardTitle + seperator + seoDictionary.app.title.about,
    "about.description": seoDictionary.app.description.about + seperator + standardDescription,

    "feedback": routes.app.feedback,
    "feedback.shortTitle": seoDictionary.app.title.feedback,
    "feedback.title": standardTitle + seperator + seoDictionary.app.title.feedback,
    "feedback.description": seoDictionary.app.description.feedback + seperator + standardDescription,

    "chooseLanguage": routes.app.chooseLanguage,
    "chooseLanguage.title": standardTitle + seperator + seoDictionary.app.title.chooseLanguage,
    "chooseLanguage.description": seoDictionary.app.description.chooseLanguage + seperator + standardDescription,

    "privacy": routes.app.privacy,
    "privacy.title": standardTitle + seperator + seoDictionary.app.title.privacy,
    "privacy.description": seoDictionary.app.description.privacy + seperator + standardDescription,

    "myPolls": routes.app.myPolls,
    "myPolls.title": standardTitle + seperator + seoDictionary.app.title.myPolls,

    "createPoll": routes.app.createPoll,
    "createPoll.title": standardTitle + seperator + seoDictionary.app.title.createPoll,
    "createPoll.shortTitle": seoDictionary.app.title.createPoll,

    "editPoll": routes.app.editPoll,
    "editPoll.title": standardTitle + seperator + seoDictionary.app.title.editPoll,
    "editPoll.shortTitle": seoDictionary.app.title.editPoll,

    "pollResponses": routes.app.pollResponses,
    "pollResponses.title": standardTitle + seperator + seoDictionary.app.title.pollResponses,
    "pollResponses.shortTitle": seoDictionary.app.title.pollResponses,

    "pollMatrix": routes.app.pollMatrix,
    "pollMatrix.title": standardTitle + seperator + seoDictionary.app.title.pollMatrix,
    "pollMatrix.shortTitle": seoDictionary.app.title.pollMatrix,

    "vote": routes.app.vote,
    "vote.title": standardTitle + seperator + seoDictionary.app.title.vote,
    "vote.shortTitle": seoDictionary.app.title.vote,

    "votePreview": routes.app.votePreview,
    "votePreview.title": standardTitle + seperator + seoDictionary.app.title.vote,
    "votePreview.shortTitle": seoDictionary.app.title.vote,

    "voteResponse": routes.app.voteResponse,
    "voteResponse.title": standardTitle + seperator + seoDictionary.app.title.voteResponse,
    "voteResponse.shortTitle": seoDictionary.app.title.voteResponse,

    "votePreviewResponse": routes.app.votePreviewResponse,
    "votePreviewResponse.title": standardTitle + seperator + seoDictionary.app.title.voteResponse,
    "votePreviewResponse.shortTitle": seoDictionary.app.title.voteResponse,

    "admin": routes.app.admin,
    "testPage": routes.app.testPage,
    "index": routes.app.index,

    "userProfile": routes.app.userProfile,
    "upgradeAnonymous": routes.app.upgradeAnonymous,
    "userLogin": routes.app.userLogin,
    "userSignup": routes.app.userSignup,
    "userPasswordReset": routes.app.userPasswordReset,

};
