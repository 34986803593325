import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EssErrorService, EssSoftwareUpdateService } from 'ngx-essentia';
import { TimePeriodClass } from 'whocan-lib';

import { environment } from '../environments/environment';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from './shared/services/responsive.service';
import { RouteContextService } from './shared/services/route-context.service';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';
import { TranslationGeneralService } from './shared/translation/translation.general.service';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    private _isLoading = true;
    constructor(
        public responsiveService: ResponsiveService,
        public routeContextService: RouteContextService,
        private logger: LoggerService,
        public dialog: MatDialog,
        public swUpdate: EssSoftwareUpdateService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private errorService: EssErrorService,
        public trans: TranslationGeneralService,
    ) { }

    async ngOnInit() {
        this.load()
        this.errorService.init(this.logger, true)

        this.swUpdate.setTimeTrigger();
        this.responsiveService.setBreakpointObserver();
        this.googleAnalyticsService.initializeAndGetConsent();
        this.routeContextService.subscribe();
        this.logger.warn('Version number', environment.appVersion);
    }

    private async load() {
        this._isLoading = true;
        try {
            await this.trans.loadTransLationFiles()
            TimePeriodClass.setAllDAyLabel(this.trans.translate('hourOption.allDay'))
            this._isLoading = false
            const trans = this.trans.translate('test.translation.success')
            this.logger.log(trans)
        } catch (e) {
            this._isLoading = false
            this.logger.error('Error loading translation files', e)
        }

    }


    get isLoading() {
        return this._isLoading
    }

    ngOnDestroy(): void {
        this.routeContextService.unsubscribe();
    }

}
