<div class="background-top-container">
    <div class="background-top">
    </div>
</div>

<div class="whoo-section-container section-container">
    <h2>
        {{labelPrefix + 'title'| transPage }}
    </h2>
    <div class="subtitle">
        {{labelPrefix + 'subtitle'| transPage }}
    </div>
    <div class="whoo-section-container section-container">

        <div>
            <div class="event-examples-item-container">
                <event-examples-item title="{{labelPrefix + 'title-1'| transPage }}"
                    [imageUrlBigScreen]="getBigScreenImage(1)" [imageUrlSmallScreen]="getSmallScreenImage(1)"
                    description="{{labelPrefix + 'description-1'| transPage }}" [imageRight]="true"
                    [templateKey]="getTemplateKey(1)" [themeKey]="getThemeKey(1)">
                </event-examples-item>
            </div>

            <div class="event-examples-item-container">
                <event-examples-item title="{{labelPrefix + 'title-2'| transPage }}"
                    [imageUrlBigScreen]="getBigScreenImage(2)" [imageUrlSmallScreen]="getSmallScreenImage(2)"
                    description="{{labelPrefix + 'description-2'| transPage }}" [imageRight]="false"
                    [templateKey]="getTemplateKey(2)" [themeKey]="getThemeKey(2)">
                </event-examples-item>
            </div>

            <div class="event-examples-item-container">
                <event-examples-item title="{{labelPrefix + 'title-3'| transPage }}"
                    [imageUrlBigScreen]="getBigScreenImage(3)" [imageUrlSmallScreen]="getSmallScreenImage(3)"
                    description="{{labelPrefix + 'description-3'| transPage }}" [imageRight]="true"
                    [templateKey]="getTemplateKey(3)" [themeKey]="getThemeKey(3)">
                </event-examples-item>
            </div>

        </div>
    </div>
    <div class="background-bottom-container">
        <div class="background-bottom">
        </div>
    </div>
</div>
