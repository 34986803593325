import { TemplateKey } from "./template-keys";
export type GenericPollTheme = {
    validForTemplates?: string[]; // the polltemplate that may use this design -if null it's valid for all templates
    filterKeywords?: string[]; // keyword bason on which the theme can be filtered -  for example on the landing pages
    themeType?: 'dark' | 'light'; // to determine  colors - default is light
    previewPicture: string; // the url of the picture that is diplay when selecting the theme
    voteStyle?: {
        bigScreen?: {
            headerBlur?: any
            backgroundContainer?: any
            background?: any,
            headerContainer?: any,
            title?: any,
            subtitle?: any,
            date?: any,
            time?: any,
            whereBox?: any,
            whereTitle?: any,
            respondTillTitle?: any,
            respondTillDate?: any,
            formContainer?: any,
            formTitle?: any

        },
        smallScreen?: {
            headerBlur?: any
            backgroundContainer?: any
            background?: any
            headerContainer?: any,
            title?: any,
            subtitle?: any,
            date?: any,
            time?: any,
            whereBox?: any,
            whereTitle?: any,
            respondTillTitle?: any,
            respondTillDate?: any,
            formContainer?: any,
            formTitle?: any

        },
    }
}

export const genericPollThemeMap = new Map<string, GenericPollTheme>([
    ['calendar', {
        validForTemplates: [TemplateKey.scheduleNew, TemplateKey.schedule, TemplateKey.advancedNew, TemplateKey.advanced],
        filterKeywords: ['schedule'],
        themeType: "light",
        previewPicture: '/assets/images/poll-templates/poll-preview/calendar.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/calendar-with-pencil.webp")',
                },
                subtitle: {
                    'color': '#490b3d'
                }
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/calendar-mobile.webp")',
                },
                subtitle: {
                    'color': '#490b3d'
                }
            }
        }
    }],
    ['human-hands', {
        validForTemplates: [TemplateKey.preference, TemplateKey.advanced, TemplateKey.preferenceNew, TemplateKey.advancedNew],
        filterKeywords: ['preference'],
        themeType: "light",
        previewPicture: '/assets/images/poll-templates/generic-poll/human-hands.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/human-hands.webp")',
                }
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/human-hands-mobile.webp")',

                }
            }
        }
    }],
    ['registration-form', {
        validForTemplates: [TemplateKey.participation, TemplateKey.advanced, TemplateKey.participationNew, TemplateKey.advancedNew],
        filterKeywords: [''],
        themeType: "dark",
        previewPicture: '/assets/images/poll-templates//generic-poll/register.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/register.webp")',
                }

            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/register.webp")',
                }
            }
        }
    }],
    ['potluck', {
        validForTemplates: [TemplateKey.potluckNew, TemplateKey.potluck, TemplateKey.advancedNew, TemplateKey.advanced],
        filterKeywords: ['potluck'],
        themeType: "dark",
        previewPicture: '/assets/images/poll-templates/poll-preview/potluck.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/potluck.webp")',
                }
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/potluck.webp")',
                }
            }
        }
    }],

    ['duties', {
        validForTemplates: [TemplateKey.dutiesDate, TemplateKey.dutiesDateNew, TemplateKey.duties, TemplateKey.dutiesNew, TemplateKey.advanced, TemplateKey.advancedNew],
        filterKeywords: [''],
        previewPicture: '/assets/images/poll-templates/poll-preview/duties.webp',
        themeType: "dark",
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/duties.webp")',
                }
            },
            smallScreen: {

                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/duties-mobile.webp")',
                }
            }
        }
    }],
    ['table-setting-festive-dinner', {

        filterKeywords: ['potluck'],
        themeType: "dark",
        previewPicture: '/assets/images/poll-templates/generic-poll/table-setting-festive-dinner-mobile.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/table-setting-festive-dinner.webp")',
                }
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/table-setting-festive-dinner-mobile.webp")',
                }
            }
        }
    }],

    ['grilled-vegetable', {
        themeType: "dark",
        filterKeywords: ['potluck'],
        previewPicture: '/assets/images/poll-templates/generic-poll/grilled-vegetable.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/grilled-vegetable.webp")',
                }
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/grilled-vegetable.webp")',
                },
            }
        }
    }],


    ['football-on-grass', {
        themeType: "dark",
        filterKeywords: [''],
        previewPicture: '/assets/images/poll-templates/generic-poll/football-on-grass.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/football-on-grass.webp")',
                }
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/football-on-grass-mobile.webp")',
                }
            }
        }
    }],
    ['board-game', {
        filterKeywords: [''],
        themeType: "dark",
        previewPicture: '/assets/images/poll-templates/generic-poll/board-game.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/board-game.webp")',
                },
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/board-game.webp")',
                }
            }
        }
    }],
    ['concert', {
        themeType: "light",
        filterKeywords: [''],
        previewPicture: '/assets/images/poll-templates/generic-poll/concert.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/concert.webp")',
                },
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/concert.webp")',
                }
            }
        }
    }],
    ['car-drivers', {
        filterKeywords: [''],
        themeType: "dark",
        previewPicture: '/assets/images/poll-templates/generic-poll/car-drivers.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/car-drivers.webp")',
                },
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/car-drivers.webp")',
                }
            }
        }
    }],
    ['gift', {
        filterKeywords: [''],
        themeType: "dark",
        previewPicture: '/assets/images/poll-templates/generic-poll/gift.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/gift.webp")',
                },
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/gift.webp")',
                }
            }
        }
    }],
    ['street', {
        filterKeywords: [''],
        themeType: "dark",
        previewPicture: '/assets/images/poll-templates/generic-poll/street.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/street.webp")',
                },
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/street-mobile.webp")',
                }
            }
        }
    }],
    ['wedding-seats', {
        filterKeywords: [''],
        themeType: "dark",
        previewPicture: '/assets/images/poll-templates/generic-poll/wedding-seats.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/wedding-seats.webp")',
                },
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/wedding-seats-mobile.webp")',
                }
            }
        }
    }],
    ['sparkler', {
        filterKeywords: [''],
        themeType: "dark",
        previewPicture: '/assets/images/poll-templates/generic-poll/sparkler-mobile.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/sparkler.webp")',
                },
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/sparkler-mobile.webp")',
                }
            }
        }
    }],
    ['yoga-mat', {
        filterKeywords: [''],
        themeType: "dark",
        previewPicture: '/assets/images/poll-templates/generic-poll/yoga-mat-mobile.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/yoga-mat.webp")',
                },
                headerBlur: {
                    'box-shadow': 'none',
                },
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/yoga-mat-mobile.webp")',

                },
                headerBlur: {
                    'box-shadow': 'none',
                },
            }
        }
    }],
    ['movie', {
        themeType: "dark",
        filterKeywords: [''],
        previewPicture: '/assets/images/poll-templates/generic-poll/movie-mobile.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/movie.webp")',
                },
                headerBlur: {
                    'box-shadow': 'none',
                },
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/movie-mobile.webp")',

                },
            }
        }
    }],
    ['reolocation', {
        themeType: "dark",
        filterKeywords: [''],
        previewPicture: '/assets/images/poll-templates/generic-poll/moving-box.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/moving-box.webp")',
                },
                headerBlur: {
                    'box-shadow': 'none',
                },
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/moving-box-mobile.webp")',
                },
                headerBlur: {
                    'box-shadow': 'none',
                },
            }
        }
    }],
    ['kids', {
        themeType: "dark",
        filterKeywords: [],
        previewPicture: '/assets/images/poll-templates/generic-poll/kids.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/kids.webp")',
                },
                headerBlur: {
                    'box-shadow': 'none',
                },
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/kids.webp")',
                },
                headerBlur: {
                    'box-shadow': 'none',
                },
            }
        }
    }],
    ['no-theme', {
        themeType: "light",
        filterKeywords: [''],
        previewPicture: '/assets/images/poll-templates/theme-preview/no-theme-preview.webp',
        voteStyle: {
            bigScreen: {
                headerContainer: {
                    'min-height': '0px'
                },

                headerBlur: {
                    'box-shadow': 'none',
                },
            },
            smallScreen: {
                headerContainer: {
                    'min-height': '0px'
                },

                headerBlur: {
                    'box-shadow': 'none',
                },
            }
        }
    }],
    ['pizza', {
        filterKeywords: [''],
        themeType: "dark",
        previewPicture: '/assets/images/poll-templates/generic-poll/pizza.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/pizza.webp")',
                }
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/pizza-mobile.webp")',
                },
            }
        }
    }],

    ['sushi', {
        filterKeywords: [''],
        themeType: "dark",
        previewPicture: '/assets/images/poll-templates/generic-poll/sushi.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/sushi.webp")',
                },
                headerContainer: {
                    'background-color': 'rgba(0, 0, 0, 0.4)'
                }

            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/sushi-mobile.webp")',
                },

                headerContainer: {
                    'background-color': 'rgba(0, 0, 0, 0.4)'
                }
            }
        }
    }],

    ['spaghetti', {
        filterKeywords: [''],
        themeType: "dark",
        previewPicture: '/assets/images/poll-templates/generic-poll/spaghetti.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/spaghetti.webp")',
                }
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/spaghetti-mobile.webp")',
                },
            }
        }
    }],
    ['order-food', {
        filterKeywords: [''],
        themeType: "dark",
        previewPicture: '/assets/images/poll-templates/generic-poll/order.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/order.webp")',
                }
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/order-mobile.webp")',
                },
            }
        }
    }],
    ['halloween', {
        filterKeywords: ['potluck'],
        themeType: "dark",
        previewPicture: '/assets/images/poll-templates/generic-poll/halloween.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/halloween.webp")',
                }
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/halloween-mobile.webp")',
                },
            }
        }
    }],
    ['christmas', {
        filterKeywords: ['potluck'],
        themeType: "dark",
        previewPicture: '/assets/images/poll-templates/generic-poll/christmas.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/christmas.webp")',
                }
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/christmas-mobile.webp")',
                },
            }
        }
    }],
    ['thanksgiving', {
        filterKeywords: ['potluck'],
        themeType: "dark",
        previewPicture: '/assets/images/poll-templates/generic-poll/thanksgiving.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/thanksgiving.webp")',
                }
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/thanksgiving-mobile.webp")',
                },
            }
        }
    }],
    ['people-register', {
        filterKeywords: [''],
        themeType: "dark",
        previewPicture: '/assets/images/poll-templates/theme-preview/people-queue-to-register.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/people-queue-to-register.webp")',
                }
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/people-queue-to-register.webp")',
                },
            }
        }
    }],
    ['feedback-from-colleague', {
        filterKeywords: [''],
        themeType: "dark",
        previewPicture: '/assets/images/poll-templates/theme-preview/feedback-from-colleague.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/feedback-from-colleague.webp")',
                }
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/feedback-from-colleague-mobile.webp")',
                },
            }
        }
    }],
    ['american-football', {
        themeType: "dark",
        filterKeywords: [''],
        previewPicture: '/assets/images/poll-templates/generic-poll/american-football.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/american-football.webp")',
                }
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/american-football-mobile.webp")',
                }
            }
        }
    }],
    ['volunteer-work', {
        themeType: "dark",
        filterKeywords: [''],
        previewPicture: '/assets/images/poll-templates/generic-poll/volunteer-work.webp',
        voteStyle: {
            bigScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/volunteer-work.webp")',
                }
            },
            smallScreen: {
                background: {
                    'background-image': 'url("../../../../../assets/images/poll-templates/generic-poll/volunteer-work-mobile.webp")',
                }
            }
        }
    }],

])

