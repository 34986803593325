<div [ngClass]="responsiveService.getBigSmall()">

    <div class="background-container">
        <div class="flex-container">

            <div *ngIf="!responsiveService.isScreenBelowMedium()" class="flex-item logo">
                <img src="assets/images/whocan-white.png" alt="{{ 'whocan'  | transMigrated}}">
            </div>
            <div class="flex-item">
                <h2>{{ 'plan-events' | transMigrated}}</h2>
                <ul>
                    <li *ngFor="let category of blogService.getCategories()">
                        <a class="category" (click)="onCategoryClick(category)"
                            [routerLink]="blogService.getCategoryRoutePath(category)" routerLinkActive="active-link"
                            matTooltip="{{category}}">
                            {{category}}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="flex-item">
                <h2>{{ 'polls' | transMigrated}}</h2>
                <ul>
                    <li>
                        <a [routerLink]="trans.getRoutePath('doodleAlternative')">{{ 'doodle-alternative' |
                            transMigrated}}</a>
                    </li>
                    <li>
                        <a routerLink="{{trans.getRoutePath('schedule')}}">{{ 'meeting-scheduler' | transMigrated}}</a>
                    </li>

                    <li>
                        <a routerLink="{{trans.getRoutePath('invitation')}}">{{ 'rsvp-invitation' | transMigrated}}</a>
                    </li>
                    <li>
                        <a routerLink="{{trans.getRoutePath('birthdayInvitation')}}">{{ 'birthday-invitation' |
                            transMigrated}}</a>
                    </li>
                    <li>
                        <a routerLink="{{trans.getRoutePath('potluck')}}">{{ 'organize-potluck' | transMigrated}}</a>
                    </li>
                    <li>
                        <a routerLink="{{trans.getRoutePath('duties')}}">{{ 'divide-tasks' | transMigrated}}</a>
                    </li>
                    <li>
                        <a routerLink="{{trans.getRoutePath('preference')}}">{{ 'ask-preferences' | transMigrated}}</a>
                    </li>
                    <li>
                        <a routerLink="{{trans.getRoutePath('registration')}}">{{ 'registration' | transMigrated}}</a>
                    </li>
                    <li>
                        <a routerLink="{{trans.getRoutePath('signupsheets')}}">{{ 'signupsheets' | transMigrated}}</a>
                    </li>
                    <li *ngIf="false">
                        <a routerLink="{{trans.getRoutePath('order')}}">{{ 'order' | transMigrated}}</a>
                    </li>
                    <li *ngIf="false">
                        <a routerLink="{{trans.getRoutePath('lunchgroup')}}">{{ 'lunchgroup' | transMigrated}}</a>
                    </li>
                </ul>
            </div>
            <div class="flex-item">
                <h2>WHOCAN</h2>
                <ul>
                    <li *ngIf="responsiveService.isScreenSmall()">
                        <a routerLink="{{trans.getRoutePath('chooseLanguage')}}">
                            Languages
                        </a>
                    </li>

                    <li>
                        <a routerLink="{{trans.getRoutePath('about')}}">{{ 'about-us' | transMigrated}}</a>
                    </li>
                    <li>
                        <a routerLink="{{trans.getRoutePath('privacy')}}">{{ 'privacy' | transMigrated}}</a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/whocan-org/" target="_blank">
                            LinkedIn
                        </a>
                    </li>
                    <li>
                        <a href="https://www.xing.com/pages/whocan" target="_blank">
                            Xing
                        </a>
                    </li>
                </ul>
            </div>
            <div class="flex-item" *ngIf="responsiveService.isScreenBig()">
                <h2>LANGUAGES</h2>
                <ul>
                    <li *ngFor="let language of trans.languages">
                        <a href="/{{language.id}}" [ngClass]="language.id===trans.locale? 'current-language':''">
                            <span> {{language.name}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="version-container">
            <span class="version">Version: {{version}} </span>
        </div>
    </div>
</div>
