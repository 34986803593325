<div class="component-container">
    <div class="flex-container">
        <div class="left">
            <div class="spiral-arrow-mobile-container ">
                <div class="spiral-arrow-mobile">
                </div>
            </div>
            <h2 class="headline">{{headline}}</h2>
            <div class="text">
                <ng-content *ngIf="!paragraph1"></ng-content>
                <span *ngIf="paragraph1">
                    <span [innerHTML]="paragraph1"></span>
                    <br><br>
                    <span [innerHTML]="paragraph2"></span>
                </span>
                <div class="name"> {{name}}</div>
            </div>
            <img *ngIf="responsiveService.isScreenSmall()" src="assets/images/background/founders.png">
        </div>
        <div *ngIf="responsiveService.isScreenBig()" class="image-container">
            <div class="image">
            </div>
        </div>
        <div class="spiral-arrow-container">
            <div class="spiral-arrow">
            </div>
        </div>
    </div>
</div>
