import { Component, Input } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../../../../shared/services/responsive.service';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { HowItWorksItem } from '../how-it-works-item/how-it-works-item';
import { CreatePollButton } from '../create-poll-button/create-poll.button';
import { EssTranslationService, TranslateLandingPagePipe } from 'ngx-essentia';
import { AbstractLandingPageSection } from '../abstract-landing-page-section/abstract-landing-page-section';

@Component({
    selector: 'how-it-works',
    standalone: true,
    imports: [
        CommonModule,
        MatDividerModule,
        RouterModule,
        MatIconModule,
        // Standalone components
        HowItWorksItem,
        CreatePollButton,
        TranslateLandingPagePipe
    ],

    templateUrl: './how-it-works.component.html',
    styleUrls: ['./how-it-works.component.scss'],
})
export class HowItWorksComponent extends AbstractLandingPageSection {
    @Input() showCreatePollButton: boolean = false;
    @Input() templateKey: string;
    @Input() bigScreenImages: string[] = [
        "assets/images/how-it-works/choose-poll-type.gif",
        "assets/images/how-it-works/set-poll-options.gif",
        "assets/images/how-it-works/vote-on-poll.gif",
        "assets/images/how-it-works/poll-vote-responses.gif"
    ];
    @Input() smallScreenImages: string[] = [
        "assets/images/how-it-works/mobile/choose-poll-type.gif",
        "assets/images/how-it-works/mobile/set-poll-options.gif",
        "assets/images/how-it-works/mobile/vote-on-poll.gif",
        "assets/images/how-it-works/mobile/poll-vote-responses.gif"
    ]
    @Input() numberOfItems: number = 4;
    @Input() sectionKey: string = "landingPageHowItWorks"

    constructor(
        private logger: LoggerService,
        public responsiveService: ResponsiveService,
        private trans: EssTranslationService

    ) { super() }

    public getBigScreenImage(index: number) {
        //  this.logger.log('getBigScreenImage', this.templateService.getLabel('landingPageHowItWorks', 'image-1', this.templateKey), this.templateKey)
        if (this.bigScreenImages && this.bigScreenImages[index - 1]) {
            return this.bigScreenImages[index - 1]
        }
        return ""

    }


    getSmallScreenImage(index: number): string {
        if (this.smallScreenImages && this.smallScreenImages[index - 1]) {
            return this.smallScreenImages[index - 1]
        } else {
            return null
        }
    }


}
