import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';
import { ResponsiveService } from '../../../../shared/services/responsive.service';
import { TopBackgroundComponent } from '../top-background/top-background.component';
import { CreatePollButton } from '../create-poll-button/create-poll.button';
import { TemplateService } from '../../../../whoo/core/poll-templates/template.service';
import { NGXLogger as LoggerService } from "ngx-logger";
import { TranslateLandingPagePipe } from 'ngx-essentia';
import { AbstractLandingPageSection } from '../abstract-landing-page-section/abstract-landing-page-section';


@Component({
    templateUrl: './section-top.component.html',

    selector: 'landing-page-section-top',
    standalone: true,
    imports: [
        CommonModule,
        MatDividerModule,
        RouterModule,

        // Standalone components
        CreatePollButton,
        TopBackgroundComponent,
        TranslateLandingPagePipe,
    ],
    styleUrls: ['./section-top.component.scss'],
})
export class SectionTopComponent extends AbstractLandingPageSection implements OnInit {
    @Input() templateKey: string;
    @Input() imageSource = "assets/images/lady-blue-shirt-pointing2.webp"
    @Input() useStandardContent = true;

    @Input() sectionKey: string = "landingPageSectionTop";
    constructor(
        public responsiveService: ResponsiveService,
        public templateService: TemplateService,
        private logger: LoggerService,

    ) {
        super()
    }
    async ngOnInit(): Promise<void> {
    }


}
