/* eslint-disable camelcase */
import { Routes } from '@angular/router';
import { Error404Page } from './app-shell/errors/404.component';
import { HomePage } from './app-shell/landing-pages/home/home.page';
import { routesSeo as routesSeoDe } from './locale/de/routes-seo';
import { routesSeo as routesSeoEn } from './locale/en/routes-seo';
import { routesSeo as routesSeoEs } from './locale/es/routes-seo';
import { routesSeo as routesSeoFr } from './locale/fr/routes-seo';
import { routesSeo as routesSeoIt } from './locale/it/routes-seo';
import { routesSeo as routesSeoPt } from './locale/pt/routes-seo';
import { AuthGuard } from './user/auth-guard-activator.service';
import { UnsavedChangesGuard } from './shared/unsaved-changes-guard/unsaved-changes.guard';

export const appRoutes: Routes = [
    { path: '', component: HomePage, data: { id: 'homeTemplates' } },
    {
        path: routesSeoEn.index,
        redirectTo: '/',
        pathMatch: 'full',
        data: { id: 'homeTemplates' },
    },
    {
        path: routesSeoEn['schedule'],
        loadComponent: () =>
            import('./app-shell/landing-pages/schedule/schedule.page').then((m) => m.SchedulePage),
        data: { id: 'schedule' },
    },
    {
        path: routesSeoDe['schedule'],
        loadComponent: () =>
            import('./app-shell/landing-pages/schedule/schedule.page').then((m) => m.SchedulePage),
        data: { id: 'schedule' },
    },
    {
        path: routesSeoIt['schedule'],
        loadComponent: () =>
            import('./app-shell/landing-pages/schedule/schedule.page').then((m) => m.SchedulePage),
        data: { id: 'schedule' },
    },
    {
        path: routesSeoEs['schedule'],
        loadComponent: () =>
            import('./app-shell/landing-pages/schedule/schedule.page').then((m) => m.SchedulePage),
        data: { id: 'schedule' },
    },
    {
        path: routesSeoFr['schedule'],
        loadComponent: () =>
            import('./app-shell/landing-pages/schedule/schedule.page').then((m) => m.SchedulePage),
        data: { id: 'schedule' },
    },
    {
        path: routesSeoPt['schedule'],
        loadComponent: () =>
            import('./app-shell/landing-pages/schedule/schedule.page').then((m) => m.SchedulePage),
        data: { id: 'schedule' },
    },
    {
        path: routesSeoEn['doodleAlternative'],
        loadComponent: () =>
            import('./app-shell/landing-pages/doodle-alternative/doodle-alternative.page').then(
                (m) => m.DoodleAlternativePage
            ),
        data: { id: 'doodleAlternative' },
    },
    {
        path: routesSeoDe['doodleAlternative'],
        loadComponent: () =>
            import('./app-shell/landing-pages/doodle-alternative/doodle-alternative.page').then(
                (m) => m.DoodleAlternativePage
            ),
        data: { id: 'doodleAlternative' },
    },
    {
        path: routesSeoIt['doodleAlternative'],
        loadComponent: () =>
            import('./app-shell/landing-pages/doodle-alternative/doodle-alternative.page').then(
                (m) => m.DoodleAlternativePage
            ),
        data: { id: 'doodleAlternative' },
    },
    {
        path: routesSeoEs['doodleAlternative'],
        loadComponent: () =>
            import('./app-shell/landing-pages/doodle-alternative/doodle-alternative.page').then(
                (m) => m.DoodleAlternativePage
            ),
        data: { id: 'doodleAlternative' },
    },
    {
        path: routesSeoFr['doodleAlternative'],
        loadComponent: () =>
            import('./app-shell/landing-pages/doodle-alternative/doodle-alternative.page').then(
                (m) => m.DoodleAlternativePage
            ),
        data: { id: 'doodleAlternative' },
    },
    {
        path: routesSeoPt['doodleAlternative'],
        loadComponent: () =>
            import('./app-shell/landing-pages/doodle-alternative/doodle-alternative.page').then(
                (m) => m.DoodleAlternativePage
            ),
        data: { id: 'doodleAlternative' },
    },






    {
        path: routesSeoEn['invitation'],
        loadComponent: () =>
            import('./app-shell/landing-pages/invitation/invitation.page').then(
                (m) => m.InvitationPage
            ),
        data: { id: 'invitation' },
    },

    {
        path: routesSeoDe['invitation'],
        loadComponent: () =>
            import('./app-shell/landing-pages/invitation/invitation.page').then(
                (m) => m.InvitationPage
            ),
        data: { id: 'invitation' },
    },

    {
        path: routesSeoIt['invitation'],
        loadComponent: () =>
            import('./app-shell/landing-pages/invitation/invitation.page').then(
                (m) => m.InvitationPage
            ),
        data: { id: 'invitation' },
    },

    {
        path: routesSeoEs['invitation'],
        loadComponent: () =>
            import('./app-shell/landing-pages/invitation/invitation.page').then(
                (m) => m.InvitationPage
            ),
        data: { id: 'invitation' },
    },

    {
        path: routesSeoFr['invitation'],
        loadComponent: () =>
            import('./app-shell/landing-pages/invitation/invitation.page').then(
                (m) => m.InvitationPage
            ),
        data: { id: 'invitation' },
    },

    {
        path: routesSeoPt['invitation'],
        loadComponent: () =>
            import('./app-shell/landing-pages/invitation/invitation.page').then(
                (m) => m.InvitationPage
            ),
        data: { id: 'invitation' },
    },


    {
        path: routesSeoEn['birthdayInvitation'],
        loadComponent: () =>
            import('./app-shell/landing-pages/invitation-birthday/birthday-invitation.page').then(
                (m) => m.BirthdayInvitationPage
            ),
        data: { id: 'birthdayInvitation' },
    },

    {
        path: routesSeoDe['birthdayInvitation'],
        loadComponent: () =>
            import('./app-shell/landing-pages/invitation-birthday/birthday-invitation.page').then(
                (m) => m.BirthdayInvitationPage
            ),
        data: { id: 'birthdayInvitation' },
    },

    {
        path: routesSeoIt['birthdayInvitation'],
        loadComponent: () =>
            import('./app-shell/landing-pages/invitation-birthday/birthday-invitation.page').then(
                (m) => m.BirthdayInvitationPage
            ),
        data: { id: 'birthdayInvitation' },
    },

    {
        path: routesSeoEs['birthdayInvitation'],
        loadComponent: () =>
            import('./app-shell/landing-pages/invitation-birthday/birthday-invitation.page').then(
                (m) => m.BirthdayInvitationPage
            ),
        data: { id: 'birthdayInvitation' },
    },

    {
        path: routesSeoFr['birthdayInvitation'],
        loadComponent: () =>
            import('./app-shell/landing-pages/invitation-birthday/birthday-invitation.page').then(
                (m) => m.BirthdayInvitationPage
            ),
        data: { id: 'birthdayInvitation' },
    },

    {
        path: routesSeoPt['birthdayInvitation'],
        loadComponent: () =>
            import('./app-shell/landing-pages/invitation-birthday/birthday-invitation.page').then(
                (m) => m.BirthdayInvitationPage
            ),
        data: { id: 'birthdayInvitation' },
    },

    {
        path: routesSeoEn['duties'],
        loadComponent: () =>
            import('./app-shell/landing-pages/duties/duties.page').then((m) => m.DutiesPage),
        data: { id: 'duties' },
    },
    {
        path: routesSeoDe['duties'],
        loadComponent: () =>
            import('./app-shell/landing-pages/duties/duties.page').then((m) => m.DutiesPage),
        data: { id: 'duties' },
    },
    {
        path: routesSeoIt['duties'],
        loadComponent: () =>
            import('./app-shell/landing-pages/duties/duties.page').then((m) => m.DutiesPage),
        data: { id: 'duties' },
    },
    {
        path: routesSeoEs['duties'],
        loadComponent: () =>
            import('./app-shell/landing-pages/duties/duties.page').then((m) => m.DutiesPage),
        data: { id: 'duties' },
    },
    {
        path: routesSeoFr['duties'],
        loadComponent: () =>
            import('./app-shell/landing-pages/duties/duties.page').then((m) => m.DutiesPage),
        data: { id: 'duties' },
    },
    {
        path: routesSeoPt['duties'],
        loadComponent: () =>
            import('./app-shell/landing-pages/duties/duties.page').then((m) => m.DutiesPage),
        data: { id: 'duties' },
    },

    {
        path: routesSeoEn['potluck'],
        loadComponent: () =>
            import('./app-shell/landing-pages/potluck/potluck.page').then((m) => m.PotluckPage),
        data: { id: 'potluck' },
    },
    {
        path: routesSeoDe['potluck'],
        loadComponent: () =>
            import('./app-shell/landing-pages/potluck/potluck.page').then((m) => m.PotluckPage),
        data: { id: 'potluck' },
    },
    {
        path: routesSeoIt['potluck'],
        loadComponent: () =>
            import('./app-shell/landing-pages/potluck/potluck.page').then((m) => m.PotluckPage),
        data: { id: 'potluck' },
    },
    {
        path: routesSeoEs['potluck'],
        loadComponent: () =>
            import('./app-shell/landing-pages/potluck/potluck.page').then((m) => m.PotluckPage),
        data: { id: 'potluck' },
    },
    {
        path: routesSeoFr['potluck'],
        loadComponent: () =>
            import('./app-shell/landing-pages/potluck/potluck.page').then((m) => m.PotluckPage),
        data: { id: 'potluck' },
    },
    {
        path: routesSeoPt['potluck'],
        loadComponent: () =>
            import('./app-shell/landing-pages/potluck/potluck.page').then((m) => m.PotluckPage),
        data: { id: 'potluck' },
    },
    {
        path: routesSeoEn['preference'],
        loadComponent: () =>
            import('./app-shell/landing-pages/preference/preference.page').then(
                (m) => m.PreferencePage
            ),
        data: { id: 'preference' },
    },
    {
        path: routesSeoDe['preference'],
        loadComponent: () =>
            import('./app-shell/landing-pages/preference/preference.page').then(
                (m) => m.PreferencePage
            ),
        data: { id: 'preference' },
    },
    {
        path: routesSeoIt['preference'],
        loadComponent: () =>
            import('./app-shell/landing-pages/preference/preference.page').then(
                (m) => m.PreferencePage
            ),
        data: { id: 'preference' },
    },
    {
        path: routesSeoEs['preference'],
        loadComponent: () =>
            import('./app-shell/landing-pages/preference/preference.page').then(
                (m) => m.PreferencePage
            ),
        data: { id: 'preference' },
    },
    {
        path: routesSeoFr['preference'],
        loadComponent: () =>
            import('./app-shell/landing-pages/preference/preference.page').then(
                (m) => m.PreferencePage
            ),
        data: { id: 'preference' },
    },
    {
        path: routesSeoPt['preference'],
        loadComponent: () =>
            import('./app-shell/landing-pages/preference/preference.page').then(
                (m) => m.PreferencePage
            ),
        data: { id: 'preference' },
    },
    {
        path: routesSeoEn['homeTemplates'],
        component: HomePage,
        data: { id: 'homeTemplates' },
    },
    {
        path: routesSeoDe['homeTemplates'],
        component: HomePage,
        data: { id: 'homeTemplates' },
    },
    {
        path: routesSeoIt['homeTemplates'],
        component: HomePage,
        data: { id: 'homeTemplates' },
    },
    {
        path: routesSeoEs['homeTemplates'],
        component: HomePage,
        data: { id: 'homeTemplates' },
    },
    {
        path: routesSeoFr['homeTemplates'],
        component: HomePage,
        data: { id: 'homeTemplates' },
    },
    {
        path: routesSeoPt['homeTemplates'],
        component: HomePage,
        data: { id: 'homeTemplates' },
    },

    //registration

    {
        path: routesSeoEn['registration'],
        loadComponent: () =>
            import('./app-shell/landing-pages/registration/registration.page').then(
                (m) => m.RegistrationPage
            ),
        data: { id: 'registration' },
    },


    {
        path: routesSeoDe['registration'],
        loadComponent: () =>
            import('./app-shell/landing-pages/registration/registration.page').then(
                (m) => m.RegistrationPage
            ),
        data: { id: 'registration' },
    },
    {
        path: routesSeoIt['registration'],
        loadComponent: () =>
            import('./app-shell/landing-pages/registration/registration.page').then(
                (m) => m.RegistrationPage
            ),
        data: { id: 'registration' },
    },
    {
        path: routesSeoEs['registration'],
        loadComponent: () =>
            import('./app-shell/landing-pages/registration/registration.page').then(
                (m) => m.RegistrationPage
            ),
        data: { id: 'registration' },
    },
    {
        path: routesSeoFr['registration'],
        loadComponent: () =>
            import('./app-shell/landing-pages/registration/registration.page').then(
                (m) => m.RegistrationPage
            ),
        data: { id: 'registration' },
    },
    {
        path: routesSeoPt['registration'],
        loadComponent: () =>
            import('./app-shell/landing-pages/registration/registration.page').then(
                (m) => m.RegistrationPage
            ),
        data: { id: 'registration' },
    },

    //signupsheets
    {
        path: routesSeoEn['signupsheets'],
        loadComponent: () =>
            import('./app-shell/landing-pages/sign-up-sheets/sign-up-sheets.page').then(
                (m) => m.SignUpSheetsPage
            ),
        data: { id: 'signupsheets' },
    },

    {
        path: routesSeoDe['signupsheets'],
        loadComponent: () =>
            import('./app-shell/landing-pages/sign-up-sheets/sign-up-sheets.page').then(
                (m) => m.SignUpSheetsPage
            ),
        data: { id: 'signupsheets' },
    },
    {
        path: routesSeoIt['signupsheets'],
        loadComponent: () =>
            import('./app-shell/landing-pages/sign-up-sheets/sign-up-sheets.page').then(
                (m) => m.SignUpSheetsPage
            ),
        data: { id: 'signupsheets' },
    },
    {
        path: routesSeoEs['signupsheets'],
        loadComponent: () =>
            import('./app-shell/landing-pages/sign-up-sheets/sign-up-sheets.page').then(
                (m) => m.SignUpSheetsPage
            ),
        data: { id: 'signupsheets' },
    },
    {
        path: routesSeoFr['signupsheets'],
        loadComponent: () =>
            import('./app-shell/landing-pages/sign-up-sheets/sign-up-sheets.page').then(
                (m) => m.SignUpSheetsPage
            ),
        data: { id: 'signupsheets' },
    },
    {
        path: routesSeoPt['signupsheets'],
        loadComponent: () =>
            import('./app-shell/landing-pages/sign-up-sheets/sign-up-sheets.page').then(
                (m) => m.SignUpSheetsPage
            ),
        data: { id: 'signupsheets' },
    },

    //order
    {
        path: routesSeoEn['order'],
        loadComponent: () =>
            import('./app-shell/landing-pages/order/order.page').then(
                (m) => m.OrderPage
            ),
        data: { id: 'order' },
    },

    {
        path: routesSeoDe['order'],
        loadComponent: () =>
            import('./app-shell/landing-pages/order/order.page').then(
                (m) => m.OrderPage
            ),
        data: { id: 'order' },
    },
    {
        path: routesSeoIt['signupsheets'],
        loadComponent: () =>
            import('./app-shell/landing-pages/order/order.page').then(
                (m) => m.OrderPage
            ),
        data: { id: 'order' },
    },
    {
        path: routesSeoEs['order'],
        loadComponent: () =>
            import('./app-shell/landing-pages/order/order.page').then(
                (m) => m.OrderPage
            ),
        data: { id: 'order' },
    },
    {
        path: routesSeoFr['order'],
        loadComponent: () =>
            import('./app-shell/landing-pages/order/order.page').then(
                (m) => m.OrderPage
            ),
        data: { id: 'order' },
    },
    {
        path: routesSeoPt['order'],
        loadComponent: () =>
            import('./app-shell/landing-pages/order/order.page').then(
                (m) => m.OrderPage
            ),
        data: { id: 'order' },
    },

    //lunchgroup
    {
        path: routesSeoEn['lunchgroup'],
        loadComponent: () =>
            import('./app-shell/landing-pages/lunch-group/lunch-group.page').then(
                (m) => m.LunchGroupPage
            ),
        data: { id: 'lunchgroup' },
    },

    {
        path: routesSeoDe['lunchgroup'],
        loadComponent: () =>
            import('./app-shell/landing-pages/lunch-group/lunch-group.page').then(
                (m) => m.LunchGroupPage
            ),
        data: { id: 'lunchgroup' },
    },
    {
        path: routesSeoIt['lunchgroup'],
        loadComponent: () =>
            import('./app-shell/landing-pages/lunch-group/lunch-group.page').then(
                (m) => m.LunchGroupPage
            ),
        data: { id: 'lunchgroup' },
    },
    {
        path: routesSeoEs['lunchgroup'],
        loadComponent: () =>
            import('./app-shell/landing-pages/lunch-group/lunch-group.page').then(
                (m) => m.LunchGroupPage
            ),
        data: { id: 'lunchgroup' },
    },
    {
        path: routesSeoFr['lunchgroup'],
        loadComponent: () =>
            import('./app-shell/landing-pages/lunch-group/lunch-group.page').then(
                (m) => m.LunchGroupPage
            ),
        data: { id: 'lunchgroup' },
    },
    {
        path: routesSeoPt['lunchgroup'],
        loadComponent: () =>
            import('./app-shell/landing-pages/lunch-group/lunch-group.page').then(
                (m) => m.LunchGroupPage
            ),
        data: { id: 'lunchgroup' },
    },

    {
        path: routesSeoEn['404'],
        component: Error404Page,
        data: { id: '404', noIndex: true },
    },
    {
        path: routesSeoEn['about'],
        loadComponent: () =>
            import('./app-shell/footer/about/about.component').then(
                (m) => m.AboutComponent
            ),
        data: { id: 'about' },
    },
    {
        path: routesSeoEn['feedback'],
        loadComponent: () =>
            import('./app-shell/footer/feedback-page/feedback.page').then(
                (m) => m.FeedbackPage
            ),
        data: { id: 'feedback', noIndex: true },
    },
    {
        path: routesSeoEn['chooseLanguage'],
        loadComponent: () =>
            import('./app-shell/footer/language/language.component').then(
                (m) => m.LanguageComponent
            ),
        data: { id: 'chooseLanguage', noIndex: true },
    },
    {
        path: routesSeoEn['privacy'],
        loadComponent: () =>
            import('./app-shell/footer/privacy/privacy.component').then(
                (m) => m.PrivacyComponent
            ),
        data: { id: 'privacy', noIndex: true },
    },
    {
        path: routesSeoEn['admin'],
        loadComponent: () =>
            import('./app-shell/admin/admin.page').then((m) => m.AdminPage),
        data: { id: 'admin', noIndex: true },
    },
    {
        path: 'test-vote',
        loadComponent: () =>
            import('./app-shell/landing-pages/test/test-vote/test-vote-page.component').then(
                (m) => m.TestVotePage
            ),
        data: { id: 'testPage', noIndex: true },
    },
    {
        path: 'test-vote' + '/:ownerId/:pollId',
        loadComponent: () =>
            import('./app-shell/landing-pages/test/test-vote/test-vote-page.component').then(
                (m) => m.TestVotePage
            ),
        canActivate: [AuthGuard],
        data: { id: 'testPage', noIndex: true },
    },

    {
        path: 'test-vote' + '/:ownerId/:pollId/:voteUserId',
        loadComponent: () =>
            import('./app-shell/landing-pages/test/test-vote/test-vote-page.component').then(
                (m) => m.TestVotePage
            ),
        canActivate: [AuthGuard],
        data: { id: 'testPage', noIndex: true },
    },

    {
        path: 'test-time-period',
        loadComponent: () =>
            import('./app-shell/landing-pages/test/test-time-period/test-time-period-page.component').then(
                (m) => m.TestTimePeriodPage
            ),
        data: { id: 'testPage', noIndex: true },
    },
    {
        path: 'test-time-period' + '/:ownerId/:pollId',
        loadComponent: () =>
            import('./app-shell/landing-pages/test/test-time-period/test-time-period-page.component').then(
                (m) => m.TestTimePeriodPage
            ),
        canActivate: [AuthGuard],
        data: { id: 'testPage', noIndex: true },
    },
    {
        path: routesSeoEn['myPolls'],
        loadChildren: () =>
            import('./whoo/features/my-polls/my-polls.module').then(
                (m) => m.MyPollsModule
            ),
        canActivate: [AuthGuard],
        data: { id: 'myPolls', noIndex: true },
    },
    {
        path: routesSeoEn['pollResponses'] + '/:ownerId/:pollId',
        loadComponent: () =>
            import('./whoo/features/poll-results/charts-and-matrix-page/charts-and-matrix.page').then(
                (m) => m.ChartsAndMatrixPage
            ),
        canActivate: [AuthGuard],
        data: { id: 'pollResponses' },
    },
    {
        path: routesSeoEn['pollMatrix'] + '/:ownerId/:pollId',
        loadComponent: () =>
            import('./whoo/features/poll-results/matrix-page/results-matrix.page').then((m) => m.PollResultsMatrixPage),
        data: { id: 'pollMatrix' },
    },
    {
        path: routesSeoEn['createPoll'],
        loadComponent: () =>
            import('./whoo/features/create-and-edit-poll/create-poll/create-poll.component').then(
                (m) => m.CreatePollComponent
            ),
        data: { id: 'createPoll' },
    },
    {
        path: routesSeoEn['createPoll'] + '/:pollTitle',
        loadComponent: () =>
            import('./whoo/features/create-and-edit-poll/create-poll/create-poll.component').then(
                (m) => m.CreatePollComponent
            ),
        data: { id: 'createPoll' },
    },
    {
        path: routesSeoEn['editPoll'] + '/:stepIndex/:ownerId/:pollId',
        loadComponent: () =>
            import('./whoo/features/create-and-edit-poll/edit-poll/edit-poll.component').then(
                (m) => m.EditPollComponent
            ),
        canActivate: [AuthGuard],
        data: { id: 'editPoll', noIndex: true },
    },
    {
        path: routesSeoEn['vote'] + '/:ownerId/:pollId',
        loadComponent: () =>
            import('./whoo/features/vote/vote-page/vote.page').then(
                (m) => m.VotePage
            ),
        canDeactivate: [UnsavedChangesGuard],
        data: { id: 'vote' },
    },
    {
        path: routesSeoEn['vote'] + '/:ownerId/:pollId/:voteUserId',
        loadComponent: () =>
            import('./whoo/features/vote/vote-page/vote.page').then(
                (m) => m.VotePage
            ),
        canDeactivate: [UnsavedChangesGuard],

        data: { id: 'vote', noIndex: true },
    },
    {
        path: routesSeoEn['votePreview'] + '/:ownerId/:pollId',
        loadComponent: () =>
            import('./whoo/features/vote/vote-preview/vote-preview.component').then(
                (m) => m.VotePreviewComponent
            ),
        canActivate: [AuthGuard],
        data: { id: 'votePreview' },
    },
    {
        path: routesSeoEn['votePreview'] + '/:ownerId/:pollId/:voteUserId',
        loadComponent: () =>
            import('./whoo/features/vote/vote-preview/vote-preview.component').then(
                (m) => m.VotePreviewComponent
            ),
        canActivate: [AuthGuard],
        data: { id: 'votePreview', noIndex: true },
    },
    {
        path: routesSeoEn['voteResponse'] + '/:ownerId/:pollId/:voteUserId?',
        loadComponent: () =>
            import('./whoo/features/vote/vote-response/vote-response.component').then(
                (m) => m.VoteResponseComponent
            ),
        data: { id: 'voteResponse', noIndex: true },
    },
    {
        path: routesSeoEn['votePreviewResponse'] + '/:ownerId/:pollId/:voteUserId?',
        loadComponent: () =>
            import('./whoo/features/vote/vote-response/vote-response.component').then(
                (m) => m.VoteResponseComponent
            ),
        canActivate: [AuthGuard],
        data: { id: 'votePreviewResponse', noIndex: true },
    },
    {
        path: 'user',
        loadChildren: () =>
            import('./user/user.module').then((m) => m.UserModule),
        data: { id: 'user', noIndex: true },
    },
    {
        path: 'blog',
        loadChildren: () =>
            import('./blog/blog.module').then((m) => m.BlogModule),
        data: { id: 'blog' },
    },

    { path: 'potluck-bottle-party-organisieren', redirectTo: 'mitbringliste-bring-a-bottle-party', pathMatch: 'full' },
    { path: 'organize-potluck', redirectTo: 'potluck-sign-up-sheet', pathMatch: 'full' },
    { path: 'organizar-el-almuerzo', redirectTo: 'lista-cosas-llevar-potluck', pathMatch: 'full' },
    { path: 'organise-un-potluck', redirectTo: 'liste-apporter-potluck', pathMatch: 'full' },
    { path: 'organizzare-il-pranzo-la-cena', redirectTo: 'lista-cose-portare-potluck', pathMatch: 'full' },
    { path: 'organizar-festa-americana', redirectTo: 'lista-coisas-levar-potluck', pathMatch: 'full' },

    { path: 'find-time-with-meeting-time-poll', redirectTo: 'scheduling-poll', pathMatch: 'full' },
    { path: 'gemeinsamen-termin-finden', redirectTo: 'terminumfrage-erstellen', pathMatch: 'full' },
    { path: 'calendrier-rendez-vous', redirectTo: 'creer-sondage-planifications', pathMatch: 'full' },
    { path: 'programar-reuniones', redirectTo: 'crear-encuesta-horarios', pathMatch: 'full' },
    { path: 'agendar-reunioes', redirectTo: 'criar-enquete-horarios', pathMatch: 'full' },
    { path: 'calendario-riunioni', redirectTo: 'creare-sondaggio-pianificazione', pathMatch: 'full' },
    { path: 'praeferenz-abstimmen', redirectTo: 'abstimung-erstellen', pathMatch: 'full' },
    { path: 'preference-poll', redirectTo: 'online-poll', pathMatch: 'full' },
    { path: 'encuesta-de-preferencias', redirectTo: 'crear-votacion', pathMatch: 'full' },
    { path: 'sondage-de-preference', redirectTo: 'creer-un-vote', pathMatch: 'full' },
    { path: 'sondaggio-di-preferenza', redirectTo: 'creare-votazione', pathMatch: 'full' },
    { path: 'sondagem-de-preferencias', redirectTo: 'criar-votacao', pathMatch: 'full' },
    { path: 'divide-tasks', redirectTo: 'task-assignment', pathMatch: 'full' },
    { path: 'aufgaben-verteilen', redirectTo: 'to-do-liste-erstellen', pathMatch: 'full' },
    { path: 'diviser-les-taches', redirectTo: 'creer-listes-choses-a-faire', pathMatch: 'full' },
    { path: 'dividir-tareas', redirectTo: 'crear-listas-cosas-por-hacer', pathMatch: 'full' },
    { path: 'dividir-tarefas', redirectTo: 'criar-listas-de-coisas-a-fazer', pathMatch: 'full' },
    { path: 'dividere-i-compiti', redirectTo: 'creare-liste-cose-da-fare', pathMatch: 'full' },
    { path: 'online-registration-tool', redirectTo: 'signupsheets', pathMatch: 'full' },
    { path: 'online-registrierung', redirectTo: 'online-terminbuchung', pathMatch: 'full' },
    { path: 'planifier-evenement-assistance', redirectTo: 'reservation-en-ligne', pathMatch: 'full' },
    { path: 'planificar-la-asistencia-a-un-evento', redirectTo: 'reserva-online', pathMatch: 'full' },
    { path: 'confirmar-presenca-em-eventos', redirectTo: 'agendamento-online', pathMatch: 'full' },
    { path: 'pianifica-evento-presenza', redirectTo: 'prenotazione-online', pathMatch: 'full' },
    { path: 'XXX', redirectTo: 'XXX', pathMatch: 'full' },
    { path: '**', redirectTo: '/404' },
];

