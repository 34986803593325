import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { ResponsiveService } from '../../../../shared/services/responsive.service';
import { TranslateLandingPagePipe } from 'ngx-essentia';

import { NGXLogger as LoggerService } from "ngx-logger";
import { TranslocoService } from '@jsverse/transloco';
import { AbstractLandingPageSection } from '../abstract-landing-page-section/abstract-landing-page-section';
import { MatTooltipModule } from '@angular/material/tooltip';

export interface BlogPostLink {
    "title": string;
    "imageUrl"?: string
    "blogPostUrl"?: string,
    "toolTip"?: string
};
@Component({
    selector: 'latest-blog-posts',
    standalone: true,
    imports: [
        CommonModule,
        MatDividerModule,
        RouterModule,
        MatIconModule,
        MatTooltipModule,
        // Standalone components
        TranslateLandingPagePipe,

    ],
    templateUrl: './latest-blog-posts.component.html',
    styleUrls: ['./latest-blog-posts.component.scss'],
})
export class LatestBlogPostsSectionComponent extends AbstractLandingPageSection {
    @Input() templateKey: string;
    @Input() sectionKey: string = "latestBlogPosts"
    constructor
        (
            public responsiveService: ResponsiveService,
            private logger: LoggerService,
            private transLoco: TranslocoService
        ) {
        super()
    }
    get blogArray(): BlogPostLink[] {
        const translateKey = this.getTransLocoArrayKey('blogArray')
        return this.transLoco.translateObject(translateKey)
    }
}
