import { Injectable } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { TranslocoService } from '@jsverse/transloco';
import { genericPollThemeMap } from '../../core/poll-templates/generic-poll-themes.configuration';
import { invitationThemeMap } from '../../core/poll-templates/invitation-themes.configuration';



@Injectable({
    providedIn: 'root'
})

export class ThemesService {
    private scope = 'themes'

    constructor(
        private logger: LoggerService,
        private transLoco: TranslocoService
    ) {
    }

    getThemeLabel(themeKey: string) {
        // return this.themeMap.get(themeKey)?.name
        const key = themeKey + '.name'
        return this.transLoco.selectTranslate(key, {}, this.scope)
    }

    public getThemeKeys(formToUse: 'standard' | 'invitation' | 'genericWithTheme' = 'invitation', templateKey: string, keywordFilter: string): string[] {
        const returnValue: string[] = []
        let themeMap: typeof invitationThemeMap | typeof genericPollThemeMap
        if (formToUse === 'invitation') {
            themeMap = invitationThemeMap
        } else if (formToUse === 'genericWithTheme') {
            themeMap = genericPollThemeMap
        } else {
            this.logger.error('wrong formToUse', formToUse)
        }
        themeMap?.forEach((themeConfig, themeKey) => {
            if (this.matchesKeyword(themeConfig, keywordFilter) && this.matchesTemplate(themeConfig, templateKey)) {
                returnValue.push(themeKey)
            }
        });
        return returnValue;
    }


    private matchesTemplate(themeConfig, templateKey): boolean {
        if (!templateKey) return true
        if (!themeConfig.validForTemplates) {
            return true
        } else {
            if (themeConfig.validForTemplates.find(templateKey => templateKey === templateKey)) {
                return true
            } else {
                return false
            }
        }
    }

    private matchesKeyword(themeConfig, keywordFilter): boolean {
        if (!keywordFilter) return true
        if (!themeConfig.filterKeywords) {
            return true
        } else {
            if (themeConfig.filterKeywords.find(keyword => keyword === keywordFilter)) {
                return true
            } else {
                return false
            }
        }
    }

}
