<div class="background-container">
    <div class="background">
    </div>
</div>
<div class="whoo-section-container">
    <h2 [innerHTML]="labelPrefix + 'headline'| transPage" class="headline">
    </h2>
    <div class="blog-array">
        <div *ngFor="let blogPost of blogArray ; let index=index" class="blog-post">
            <a [href]="blogPost.blogPostUrl" target="_blank" matTooltip="{{blogPost.title}}">
                <img [src]="blogPost.imageUrl" alt="{{blogPost.title}}" class="blog-post-image">
            </a>
            <a [href]="blogPost.blogPostUrl" target="_blank" matTooltip="{{labelPrefix + 'toolTip'| transPage}}">
                <div class="blog-post-title">{{blogPost.title}}</div>
            </a>
        </div>
    </div>
    <div class="find-latest" [innerHTML]="labelPrefix + 'findLatest'| transPage">
    </div>
</div>
