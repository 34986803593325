import { TemplateKey } from "./template-keys";
export type InvitationTheme = {
    validForTemplates: string[]; // the polltemplate that may use this design
    themeType?: 'dark' | 'light'; // to determine colors - default is light
    filterKeywords?: string[]; // keyword bason on which the theme can be filtered -  for example on the landing pages
    previewPicture: string; // the url of the picture that is diplay when selecting the theme
    leftStyle?: {
        backgroundContainer?: any;
        contentContainerBigScreen?: any;
        contentContainerSmallScreen?: any;
        backgroundBox?: any;

        pollTitle?: any;
        pollDescription?: any;
        whenBox?: any;
        whenTitle?: any;
        date?: any;
        time?: any;
        whereBox?: any;
        whereTitle?: any;
        respondTillStamp?: any;
        respondTillDate?: any;
        respondTillTitle?: any;
        commentsBox?: any;
        commentsTitle?: any;
    }

}
export const invitationThemeMap = new Map<string, InvitationTheme>([

    ['barbecue', {
        themeType: "dark",
        validForTemplates: [TemplateKey.invitation],
        filterKeywords: [],
        previewPicture: '/assets/images/poll-templates/invitation/barbeque-dark-2.webp',
        leftStyle: {
            backgroundContainer: {
                'background-image': 'url("/assets/images/poll-templates/invitation/barbeque-dark-2.webp")',
            },
        }
    }],
    ['ballons', {
        validForTemplates: [TemplateKey.invitation],
        filterKeywords: ['birthday'],
        previewPicture: '/assets/images/poll-templates/theme-preview/ballons.webp',
        themeType: "light",
        leftStyle: {
            backgroundContainer: {
                'background-image': 'url("/assets/images/poll-templates/invitation/colorful-party-balloons.webp")',
            },
            contentContainerSmallScreen: {
                'background-color': 'rgba(255, 255, 255, 0.4)',
            },
            contentContainerBigScreen: {
                'background-color': 'rgba(255, 255, 255, 0.6)',
            },
        },

    }],
    ['barbecue-veggie', {
        validForTemplates: [TemplateKey.invitation],
        filterKeywords: [],
        previewPicture: '/assets/images/poll-templates/invitation/grilled-tasty-asssorted-vegetab.webp',
        themeType: "dark",
        leftStyle: {
            backgroundContainer: {
                'background-image': 'url("/assets/images/poll-templates/invitation/grilled-tasty-asssorted-vegetab.webp")',
            },
            contentContainerBigScreen: {
                'backdrop-filter': 'blur(8px)',
            },
        }
    }],
    ['thanksgiving-dinner', {
        validForTemplates: [TemplateKey.invitation],
        filterKeywords: [],
        previewPicture: '/assets/images/poll-templates/invitation/thanksgiving-dinner.webp',
        themeType: "dark",
        leftStyle: {
            backgroundContainer: {
                'background-image': 'url("/assets/images/poll-templates/invitation/thanksgiving-dinner.webp")',
            },
        }
    }],
    ['festive-dinner', {
        validForTemplates: [TemplateKey.invitation],
        filterKeywords: ['birthday'],
        previewPicture: '/assets/images/poll-templates/invitation/table-setting-for-a-festive-dinner.webp',
        themeType: "dark",
        leftStyle: {
            backgroundContainer: {
                'background-image': 'url("/assets/images/poll-templates/invitation/table-setting-for-a-festive-dinner.webp")',
            },
        }

    }],
    ['wedding-wedding-bouquet', {
        validForTemplates: [TemplateKey.invitation],
        filterKeywords: [],
        previewPicture: '/assets/images/poll-templates/invitation/man-and-woman-with-wedding-bouquet.webp',
        themeType: "dark",
        leftStyle: {
            backgroundContainer: {
                'background-image': 'url("/assets/images/poll-templates/invitation/man-and-woman-with-wedding-bouquet.webp")',
            },
            contentContainerBigScreen: {
                'backdrop-filter': 'blur(8px)',
            },
        },

    }],
    ['halloween-table-with-pumpkin', {
        validForTemplates: [TemplateKey.invitation],
        filterKeywords: [],
        previewPicture: '/assets/images/poll-templates/invitation/halloween-table-with-punpkin.webp',
        themeType: "dark",
        leftStyle: {
            backgroundContainer: {
                'background-image': 'url("/assets/images/poll-templates/invitation/halloween-table-with-punpkin.webp")',
            },
            contentContainerBigScreen: {
                'backdrop-filter': 'blur(8px)',
            },
        },
    }],
    ['vine-glasses', {
        validForTemplates: [TemplateKey.invitation],
        filterKeywords: [],
        previewPicture: '/assets/images/poll-templates/invitation/glasses-with-various-craft-wine.webp',
        themeType: "dark",
        leftStyle: {
            backgroundContainer: {
                'background-image': 'url("/assets/images/poll-templates/invitation/glasses-with-various-craft-wine.webp")',
            },
            contentContainerBigScreen: {
                'backdrop-filter': 'blur(8px)',
            }
        }
    }],
    ['turntable', {
        validForTemplates: [TemplateKey.invitation],
        filterKeywords: [],
        previewPicture: '/assets/images/poll-templates/invitation/disc-jockey-at-the-turntable.webp',
        themeType: "dark",
        leftStyle: {
            backgroundContainer: {
                'background-image': 'url("/assets/images/poll-templates/invitation/disc-jockey-at-the-turntable.webp")',
            },
            contentContainerBigScreen: {
                'backdrop-filter': 'blur(12px)',
            },
        }
    }],
    ['disc-jockey', {
        validForTemplates: [TemplateKey.invitation],
        filterKeywords: [],
        previewPicture: '/assets/images/poll-templates/invitation/disco-dj-playing.webp',
        themeType: "light",
        leftStyle: {
            backgroundContainer: {
                'background-image': 'url("/assets/images/poll-templates/invitation/disco-dj-playing.webp")',
            },
            contentContainerBigScreen: {
                'backdrop-filter': 'blur(25px)',
            }
        },

    }],
    ['business-people', {
        validForTemplates: [TemplateKey.invitation],
        filterKeywords: [],
        previewPicture: '/assets/images/poll-templates/invitation/group-of-business-people.webp',
        themeType: "dark",
        leftStyle: {
            backgroundContainer: {
                'background-image': 'url("/assets/images/poll-templates/invitation/group-of-business-people.webp")',
            },
            contentContainerBigScreen: {
                'backdrop-filter': 'blur(12px)',
            },
        },

    }],
    ['fireworks', {
        validForTemplates: [TemplateKey.invitation],
        filterKeywords: [],
        previewPicture: '/assets/images/poll-templates/invitation/firework-show-on-new-years-eve.webp',
        themeType: "dark",
        leftStyle: {
            backgroundContainer: {
                'background-image': 'url("/assets/images/poll-templates/invitation/firework-show-on-new-years-eve.webp")',
            },
            contentContainerBigScreen: {
                'backdrop-filter': 'blur(2px)',
            }
        }
    }],
    ['kids', {
        validForTemplates: [TemplateKey.invitation],
        filterKeywords: ['birthday'],
        previewPicture: '/assets/images/poll-templates/generic-poll/kids.webp',
        themeType: "dark",
        leftStyle: {
            backgroundContainer: {
                'background-image': 'url("/assets/images/poll-templates/generic-poll/kids.webp")',
            },
            contentContainerBigScreen: {
                'backdrop-filter': 'blur(2px)',
            }
        }
    }],
])



