import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { ResponsiveService } from '../../../../shared/services/responsive.service';
import { TranslateTemplatePipe } from '../../../../whoo/core/poll-templates/template-label/template-label.pipe';
import { CreatePollButton } from '../create-poll-button/create-poll.button';
import { TranslateLandingPagePipe } from 'ngx-essentia';
import { AbstractLandingPageSection } from '../abstract-landing-page-section/abstract-landing-page-section';
@Component({
    selector: 'whocan-advantages',

    standalone: true,
    imports: [
        CommonModule,
        MatDividerModule,
        RouterModule,
        MatIconModule,
        // Standalone components
        CreatePollButton,
        TranslateLandingPagePipe
    ],
    templateUrl: './whocan-advantages.component.html',
    styleUrls: ['./whocan-advantages.component.scss'],
})
export class WhocanAdvantagesComponent extends AbstractLandingPageSection {
    @Input() templateKey: string;
    @Input() numberOfAdvantages: number = 5;
    @Input() sectionKey: string = "landingPageAdvantages"
    constructor(public responsiveService: ResponsiveService) {
        super()
    }


}
