import { Component, Input } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../../../../shared/services/responsive.service';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';;
import { HowItWorksItem } from '../how-it-works-item/how-it-works-item';
import { CreatePollButton } from '../create-poll-button/create-poll.button';
import { EssTranslationService, TranslateLandingPagePipe } from 'ngx-essentia';
import { EventExamplesItemComponent } from '../event-examples-item/event-examples-item';
import { AbstractLandingPageSection } from '../abstract-landing-page-section/abstract-landing-page-section';

@Component({
    selector: 'event-examples',
    standalone: true,
    imports: [
        CommonModule,
        MatDividerModule,
        RouterModule,
        MatIconModule,
        // Standalone components
        TranslateLandingPagePipe,
        EventExamplesItemComponent
    ],

    templateUrl: './event-examples.component.html',
    styleUrls: ['./event-examples.component.scss'],
})
export class EventExamplesComponent extends AbstractLandingPageSection {
    @Input() showCreatePollButton: boolean = false;
    @Input() templateKey: string;
    @Input() bigScreenImages: string[];
    @Input() smallScreenImages: string[]
    @Input() templateKeys: string[];
    @Input() themeKeys: string[];
    @Input() numberOfItems: number = 4;
    @Input() sectionKey: string = "eventIdeas"


    constructor(
        private logger: LoggerService,
        public responsiveService: ResponsiveService,
        private trans: EssTranslationService

    ) { super() }

    public getBigScreenImage(index: number) {
        if (this.bigScreenImages && this.bigScreenImages[index - 1]) {
            return this.bigScreenImages[index - 1]
        }
        return ""

    }

    public getTemplateKey(index: number) {
        if (this.templateKeys && this.templateKeys[index - 1]) {
            return this.templateKeys[index - 1]
        }
        return null

    }

    public getThemeKey(index: number) {
        if (this.themeKeys && this.themeKeys[index - 1]) {
            return this.themeKeys[index - 1]
        }
        return null

    }

    getSmallScreenImage(index: number): string {
        if (this.smallScreenImages && this.smallScreenImages[index - 1]) {
            return this.smallScreenImages[index - 1]
        } else {
            return null
        }
    }


}
