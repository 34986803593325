<div class="background-container">
    <div class="background">
    </div>
</div>
<div class="whoo-section-container section-1-container">
    <div class="section-1">
        <!--left-->
        <div class="box-container">
            <h2>{{labelPrefix + 'headline'| transPage }}</h2>
            <ul>
                <li>
                    <h3>{{labelPrefix + 'title-1'| transPage }}</h3>
                    <p>
                        {{labelPrefix + 'text-1'| transPage }}
                    </p>
                </li>
                <li *ngIf="numberOfAdvantages>1">
                    <h3>{{labelPrefix + 'title-2'| transPage }}</h3>
                    <p>
                        {{labelPrefix + 'text-2'| transPage }}
                    </p>
                </li>
                <li *ngIf="numberOfAdvantages>2">
                    <h3>{{labelPrefix + 'title-3'| transPage }}</h3>
                    <p>
                        {{labelPrefix + 'text-3'| transPage }}
                    </p>
                </li>
                <li *ngIf="numberOfAdvantages>3">
                    <h3>{{labelPrefix + 'title-4'| transPage }}</h3>
                    <p>
                        {{labelPrefix + 'text-4'| transPage }}
                    </p>
                </li>
                <li *ngIf="numberOfAdvantages>4">
                    <h3>{{labelPrefix + 'title-5'| transPage }}</h3>
                    <p>
                        {{labelPrefix + 'text-5'| transPage }}
                    </p>
                </li>

                <li *ngIf="numberOfAdvantages>5">
                    <h3>{{labelPrefix + 'title-6'| transPage }}</h3>
                    <p>
                        {{labelPrefix + 'text-6'| transPage }}
                    </p>
                </li>
                <li *ngIf="numberOfAdvantages>6">
                    <h3>{{labelPrefix + 'title-7'| transPage }}</h3>
                    <p>
                        {{labelPrefix + 'text-7'| transPage }}
                    </p>
                </li>
                <li *ngIf="numberOfAdvantages>7">
                    <h3>{{labelPrefix + 'title-8'| transPage }}</h3>
                    <p>
                        {{labelPrefix + 'text-8'| transPage }}
                    </p>
                </li>
                <li *ngIf="numberOfAdvantages>8">
                    <h3>{{labelPrefix + 'title-9'| transPage }}</h3>
                    <p>
                        {{labelPrefix + 'text-9'| transPage }}
                    </p>
                </li>
                <li *ngIf="numberOfAdvantages>9">
                    <h3>{{labelPrefix + 'title-10'| transPage }}</h3>
                    <p>
                        {{labelPrefix + 'text-10'| transPage }}
                    </p>
                </li>
                <li *ngIf="numberOfAdvantages>10">
                    <h3>{{labelPrefix + 'title-11'| transPage }}</h3>
                    <p>
                        {{labelPrefix + 'text-11'| transPage }}
                    </p>
                </li>
            </ul>
        </div>
        <!--right-->
        <div class="image-container">
            <div class="background">
            </div>
        </div>
    </div>
    <div class="create-poll-button-container">
        <div class="create-poll-button-background-container">
            <div class="create-poll-button-background">
            </div>
        </div>
        <create-poll-button [pollTemplateKey]="templateKey" [showField]="false"
            buttonLabel="{{labelPrefix + 'buttonLabel'| transPage }}">
        </create-poll-button>
    </div>

</div>
